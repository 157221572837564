import { Pipe, PipeTransform } from '@angular/core';
import { ILocation } from '../models/location/location.model';

@Pipe({
  name: 'locationName',
  standalone: true
})
export class LocationNamePipe implements PipeTransform {

  transform(location: ILocation | null | undefined, d: { useInternalTitle: boolean, isOnline?: boolean }): string | null {
    if (d.isOnline) {
      return 'online';
    } else {
      if (d.useInternalTitle) {
        return location?.internalTitle || location?.title || null;
      } else {
        return location?.title ?? null;
      }
    }
  }

}
